import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'; 
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";
;
import { Observer } from 'rxjs';
interface TokenResponse {
  status: string,
  data: {
    token: string
    user_id: string
    user_type: string
  }
}
@Injectable()
export class AdminService {
  private token: any
  private user_id: any
  private user_type: any
  constructor(private http: HttpClient, private router: Router) {
    if (Constants.APICONFIG.HOST == 'localhost') {
      this.APIURL = 'http://localhost:3000/v1';
      this.PIVOTALAPIURL = 'http://localhost:3200/api';
  }
  }

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`
  PIVOTALAPIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/api`;
 
    //function to validateUser
    public getLabs(req_vars:any): Observable<any> {
      return this.http.post(`${this.APIURL}/labs`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    //function to validateUser
    public updateLabs(req_vars:any): Observable<any> {
      return this.http.post(`${this.APIURL}/updateLabs`,req_vars,  { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    
    public getLabDetails(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/getLabDetails`, req_vars)
    }
    
    public patientList(req_vars:any): Observable<any> {
      return this.http.post(`${this.APIURL}/patientList`,req_vars,  { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public customerList(req_vars:any): Observable<any> {
      return this.http.post(`${this.APIURL}/customerList`,req_vars,  { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public activityLogs(req_vars:any): Observable<any> {
      return this.http.post(`${this.APIURL}/activityLogs`,req_vars,  { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public getConstants(): Observable<any> {
        return this.http.get(`${this.APIURL}/constants`, this.noAuthHeader)
    }

    public usersList(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/userList`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public deactivate(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/deactivate`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public getDetails(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/doctorDetails`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public getClinics(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/getClinics`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public updateClinics(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/createClinic`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public updateProfile(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/updateProfile`,req_vars, { headers: new HttpHeaders({ forAdmin: 'true' }) })
    }

    public validateUser(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/validateUser`,req_vars, this.noAuthHeader)
    }

    public register(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/users`,req_vars, this.noAuthHeader)
    }

    public generateOTP(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/generateOtp`,req_vars, this.noAuthHeader)
    }

    public adminforgotPassword(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/adminforgotPassword`,req_vars, this.noAuthHeader)
    }

    public checkResetLink(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/checkResetLink`,req_vars, this.noAuthHeader)
    }

    public changePassword(req_vars: any): Observable<any> {
      return this.http.post(`${this.APIURL}/changePassword`,req_vars, this.noAuthHeader)
    }

    public adminGetAllDocs(req_vars: any): Observable<any>{
      return this.http.get(`${this.APIURL}/adminGetAllDocs`,req_vars)
    }
    public admingetDoctorsStats(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctorsStats`,req_vars)
    }
    public getPendingVaccinesOn7thDay(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/getPendingVaccinesOn7thDay`,req_vars)
    }
    public admingetDoctorsPatientsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctorsPatientsRecords`,req_vars)
    }
    public admingetMedicalConditionsRecord(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetMedicalConditionsRecord`,req_vars)
    }
    
    public admingetDoctPrescriptionsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctPrescriptionsRecords`,req_vars)
    }
    public admingetDoctPatAppointmentsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctPatAppointmentsRecords`,req_vars)
    }
    public admingetDoctPatRequisitionsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctPatRequisitionsRecords`,req_vars)
    }
    public admingetDoctPatLabReportsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDoctPatLabReportsRecords`,req_vars)
    }
    public admingetDocCustomersRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetDocCustomersRecords`,req_vars)
    }
    public admingetHealthNotInstalledRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetHealthNotInstalledRecords`,req_vars)
    }
    public admingetPatGivenVaccRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetPatGivenVaccRecords`,req_vars)
    }
    public admingetPatNotGivenVaccRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetPatNotGivenVaccRecords`,req_vars)
    }
    public admingetPatPushNotificationsRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/admingetPatPushNotificationsRecords`,req_vars)
    }
    public salesTeamCategory(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/salesTeamCategory`,req_vars)
    }
    public salesTeamCategoryRecords(req_vars:any): Observable<any>{
      return this.http.post(`${this.APIURL}/salesTeamCategoryRecords`,req_vars)
    }
    public doctorRegister(req_vars:any): Observable<any> {
      return this.http.post(`${this.PIVOTALAPIURL}/doctor/doctorRegister`, { data: req_vars })
    }

  // public saveServicesByDoctorId(req_vars:any): Observable<any>{
  //   return this.http.post(`${this.APIURL}/saveServicesByDoctorId`,req_vars)
  // }
  public saveServicesByDoctorId(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/saveServicesByDoctorId`, req_vars)
}
  

  public getAllDoctorRxTemplate(req_vars:any): Observable<any>{
    return this.http.post(`${this.APIURL}/getAllDoctorRxTemplate`,req_vars)
  }

  public saveRxTemplateByDocUserId(req_vars:any): Observable<any>{
    return this.http.post(`${this.APIURL}/saveRxTemplateByDocUserId`,req_vars)
  }
  public getManagementReports(req_vars:any): Observable<any> {
    return this.http.post(`${this.PIVOTALAPIURL}/doctor/getManagementReports`, { data: req_vars })
  }
  public getAllSalesPersons(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getAllSalesPersons`, { data: req_vars })
  }

  public getAllLinkedRecords(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getAllLinkedRecords`,req_vars)
  }
  public getSelectedLinkedRecords(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getSelectedLinkedRecords`,req_vars)
  }
  public monitoringRecordsCount(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/monitoringRecordsCount`,req_vars)
  }
  public getSelectedArogyamitraSupervisor(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getSelectedArogyamitraSupervisor`,req_vars)
  }
  
  public getPaymentTransactions(req_vars:any):Observable<any>{
    return this.http.post(`${this.APIURL}/admin/getPaymentTransactions`,req_vars)
  }
  
}
